let resumeData = {
    "imagebaseurl": "https://github.com/Eman22S/",
    "name": "Iman Asfaw",
    "role": "Machine learning Engineer and Data Scientist",
    "linkedinId": "https://www.linkedin.com/in/iman-asfaw-83473684/",
    "skypeid": "Your skypeid",
    "roleDescription": "I have 5 years of experience in Machine learning and A.I. I'm Looking for Machine learning and Data Science roles. Available for full time employment under W2", 
    
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/iman-asfaw-83473684/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url": "https://github.com/Eman22S/", 
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":" ",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I am an ambitious Data Scientist, I like working on Computer Vision. I am result driven and a strong team player",
    "address":"Ethiopia",
    "website":"imansportfolio.site",
    "education":[
      {
        "UniversityName":"Maharishi International University",
        "specialization":"Master's of Computer Science",
        "MonthOfPassing":"November",
        "YearOfPassing":"2023",
        "Achievements":"-"
      },
      {
        "UniversityName":"Addis Ababa Univeristy",
        "specialization":"Software Engineering",
        "MonthOfPassing":"March",
        "YearOfPassing":"2020",
        "Achievements":"-"
      }
    ],
    "work":[
      {
        "CompanyName":"Mereb Technology",
        "specialization":"Software Engineer",
        "MonthOfLeaving":"Sept",
        "YearOfLeaving":"2023",
        "Achievements":"Designed a feature for the cryptocurrency module that allows users to view wallets and transfer credit using Express.js and Node.js, helping users manage their crypto accounts more effectively. Developed a solution for the admin module to efficiently retrieve user data from a MongoDB database using JavaScript and Node.js, resulting in an 80% improvement in performance compared to the previous version. Dockerized backend server with MySQL and Redis integration, streamlining testing and increasing development speed by 70%. Contributed to all aspects of Software Development life cycle resulting in efficient development, integration testing and deployment of code which resulted in promotion and client satisfaction."
      },
      {
        "CompanyName":"Research Fellow",
        "specialization":"Computer Vision",
        "MonthOfLeaving":"Nov",
        "YearOfLeaving":"2021",
        "Achievements":" Developed an unsupervised AI algorithm in Python for genetics research, identifying differences in human cell datasets to distinguish between cancer-affected and non-affected cells, earning a $1500 award.  The model used Anomaly detection  to achieve  the result. Discovered key cancer-related genetic components using an AI model on real cell datasets, achieving 90% accuracy in a published research paper accepted at workshops"
      },

      {
        "CompanyName":"Machine learning Engineer",
        "specialization":"Natural language Processing",
        "MonthOfLeaving":"Nov",
        "YearOfLeaving":"2021",
        "Achievements": "Contributed to developing a highly efficient AI algorithm in C++ for MOSES, achieving 98% accuracy. Developed a Scheme-based database querying module which involves graph learning for AtomSpace, a Graph database, increasing performance by nearly 70%." 
        +"Tested the Opencog AGI agent with a Ping-pong OpenAI gym environment using Python, helping iCogLabs secure significant funding through a client demo. "
        +"Contributed to Sofia Robot’s language knowledge base by writing chatscript codes and integrated with her ROS’s system using bash script."
        +"Conducted unit and system testing with CxxTest, and performed code fixes and enhancements for future releases and patches. "
        +"Built a fake news classifier using Idris and Haskell with 95% accuracy, demonstrating the languages' effectiveness in machine learning." 
        +"Contributed to all Machine learning life cycle of train, test and deploy of AI/ML applications resulting in high client satisfaction."
        +"Contributed to the development of an Ethiopian Virtual Assistant for call centers by training wav2letter and achieved an accuracy of 91%. "
        +"Acted as a front end developer and designed a UI for the Ethiopian virtual assistant using javascript for demo which attracted business and helped my company generate a revenue."
        +"Processed tabular datasets using R and Python for the child mortality prediction model, enhancing prediction accuracy. " 
        +"Trained Neural Networks and Random Forest models to predict child mortality rates and Vitamin A coverage with R2-squares of 0.98 and 0.96, respectively, and performed data analysis and feature extraction to provide critical insights for field experts." 
        +"Conducted prediction interpretation using LIME, helping field experts understand and interpret the model's predictions"
      }

    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"Python"
      },
      {
        "skillname":"PyTorch"
      },
      {
        "skillname":"Keras"
      },
      {
        "skillname": "R"
      }
    ],
    "portfolio":[
      {
        "name":"Speect to Text",
        "description":"mobileapp",
        "imgurl":"images/portfolio/coffee.jpg"
      },
      {
        "name":"Sports Logo Detection",
        "description":"A.I model",
        "imgurl":"images/portfolio/retrocam.jpg"
      },
      {
        "name":"Unsupervised Annotation of differences b/n genomic dataset",
        "description":"A.I model",  
        "imgurl":"images/portfolio/origami.jpg"
      },
      {
        "name":"American Sign language to text",
        "description":"A.I model",
        "imgurl":"images/portfolio/console.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"Iman is a bright and enthusiastic worker and definitely has my recommendation. I have watched her grow and stand out when she was interning in ICog-Labs which I Co-Founded. I have always praised her energy in the company and requested her to do mentoring for incoming interns to follow her footsteps. She always works on acquiring new skills and growing her knowledge on machine learning. One quality that I admire the most is her ability to focus and be productive in a highly tense environment. Her hard-work and commitment is unquestionable to me as I have seen it in my company",
        "name":"Genet Assefa, CEO"
      },
      {
        "description":"Iman was my co-worker and an amazing machine learning expert at iCog-Labs. Working with her over the years on multiple projects, I have had the pleasure of watching this young woman blossom into a focused, self-assured scholar who aspires to a career combining both research and development. Eman's strong desire to succeed transformed this once an intern student into an important contributor to the lab. With her outstanding ideas and skills, our team managed to finish the AS-MOSES project ahead of time. Eman would surely be an asset to any company working on machine learning and AI",
        "name":"Bitseat Tadesse Aragaw"
      }
    ]
  }
  
  export default resumeData
