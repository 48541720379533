import React, { Component } from 'react';

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      subject: '',
      message: '',
      responseMessage: '',
      isSubmitting: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true, responseMessage: '' });

    const apiEndpoint = 'https://vsf0ygis63.execute-api.us-east-1.amazonaws.com/contactdev/contactme'; 

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          subject: this.state.subject,
          message: this.state.message,
        }),
      });

      const data = await response.json();
      this.setState({ isSubmitting: false });
      console.log(data, response)
      if (response.ok) {
        this.setState({
          responseMessage: data.message || 'Your message has been sent successfully!',
          email: '',
          subject: '',
          message: '',
        });
      } else {
        this.setState({
          responseMessage: data.error || 'Failed to send your message. Please try again later.',
        });
      }
    } catch (error) {
      console.error('Fetch error:', error);
      this.setState({
        isSubmitting: false,
        responseMessage: 'Error submitting the form. Please try again later.',
      });
    }
  };

  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead">
            Contact Me!
            </p>
          </div>
        </div>
        <div className="row">
          {/* <aside className="eight columns footer-widgets">
            <div className="widget">
              <h4>LinkedIn: {resumeData.linkedinId}</h4>
            </div>
          </aside> */}
        </div>
        <div className="row">
          <div className="twelve columns">
            <form onSubmit={this.handleSubmit}>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div>
                <label>Subject:</label>
                <input
                  type="text"
                  name="subject"
                  value={this.state.subject}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div>
                <label>Message:</label>
                <textarea
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" disabled={this.state.isSubmitting}>
                {this.state.isSubmitting ? 'Sending...' : 'Submit'}
              </button>
            </form>
            {this.state.responseMessage && <p>{this.state.responseMessage}</p>}
          </div>
        </div>
      </section>
    );
  }
}
